import {SITE_IS_OPEN} from "@/config";

export const siteIsClose = () => {
    let user = JSON.parse(localStorage.getItem('user'));
    let params = (new URL(document.location)).searchParams;
    let isAdmin = user !== null && user.data.attributes.role === 'admin';
    let isOpen = params.get('open') === 'true'

    if (!SITE_IS_OPEN) {

        if (isAdmin) {
            return false
        }

        if (isOpen) {
            return false
        }

        return true
    }

    return false
}

/**
 * Возвращаем слово 'день' в нужном склонении
 *
 * @param number - кол-во дней
 *
 * @return String - 'день' || 'дней' || 'дня'
 * */
export const getDayTextDeclination = (number) => {
    number = Math.abs(number) % 100;
    let remainder = number % 10;

    if(number > 10 && number < 20) return 'дней';
    if(remainder > 1 && remainder < 5) return 'дня';
    if(remainder === 1) return 'день';
    return 'дня';
};

/**
 * Функция проверяет есть ли активная заявка от текущего пользователя.
 *
 * В ходе выполнения функции мы рассортировываем заявки по турнирам,
 * Затем проверяем есть ли в этих заявках тукущий пользователь.
 * Если есть, то добавляем турниру атрибут 'currentUserActiveRequest'
 *
 * @param tournaments - массив туринров
 * @param entries - массив заявок на турниры
 * @param currentUser - текущий пользователь
 *
 * @return tournaments - мутированный массив турниров
 * */
export const isUserHaveActiveTournamentRequest = (tournaments, entries, currentUser) => {
    tournaments.map(tournament => {
        let tournamentEntries = [];

        for (let i = 0; i < entries.length; i++) {
            if (Number(entries[i].relationships.receivable.data.id) === Number(tournament.id)) {
                tournamentEntries.push(entries[i]);
            }
        }

        for (let i = 0; i < tournamentEntries.length; i++) {
            if (Number(tournamentEntries[i].user?.id) === Number(currentUser.id)) {
                tournament.currentUserActiveRequest = true;
                break;
            }
        }
    });

    return tournaments;
}