import {API} from "@/Vuex/axios-config";
import {filteringByUserRole, includeFilteringByTag, includeFilteringByType} from "@/otherJS/include-filtering";
import _ from "lodash";

/*
    Принимает объект params;
    Список принимаемых переменных:
    - url - урл для запроса, например: /users
    - parameters - список параметров для запроса, например: ?
    - method - GET/POST/PATCH etc.
    - headers - объект, если например нужна авторизация
*/

export default class FetchingData {
    constructor(props) {
        this.url = props.url;
        this.params = props.params;
        this.method = props.method;
        this.headers = props.headers;
    }

    get data() {
        return API({
            url: this.url+this.params,
            method: this.method,
            headers: this.headers ? this.headers : null
        }).then(res => {
            return res.data
        })
    }

    async createUser() {
        return this.data.then(res => {
            let data = res.data

            let logos = this.getDataByTag(res.included,'logo');
            let background = includeFilteringByTag(res.included,'background');
            let allTeams = this.getDataByType(res.included, 'teams').map(team => {
                team.logo = logos.filter(logo => logo.id === team.relationships.logo.data.id)[0].attributes.url
                team.userRole = team.relationships.users.meta.roles.find(role => {
                    if(Number(role.id) === Number(data.id)) {
                        return role
                    }
                }).role
                return team;
            });

            const compositionsForDisciplines = includeFilteringByType(res.included, 'compositions-for-disciplines');

            data.logo = logos.filter(logo => logo.id === data.relationships.logo.data.id)[0].attributes.url;
            data.background = background.filter(item => item.id === data.relationships.background.data.id)[0].attributes.url;
            let teams = this.getDataByUserRole(allTeams, 'gamer')
            let managedTeams = this.getDataByUserRole(allTeams, 'manager')

            return {
                data,
                teams,
                managedTeams,
                compositionsForDisciplines
            };
        })
    }

    createTeam() {
        return this.data.then(res => {
            let team = res.data;

            let logos = this.getDataByTag(res.included,'logo');
            let background = this.getDataByTag(res.included,'background');

            let users = this.getDataByType(res.included, 'users').map(user => {
                user.logo = logos.filter(logo => logo.id === user.relationships.logo.data.id)[0].attributes.url;
                user.role = team.relationships.users.meta.roles.find(role => Number(role.id) === Number(user.id) ? role : null).role
                user.games = []

                return user
            });
            users.unshift(...users.splice(_.findIndex(users, item => item.role === 'manager'),1))

            let games = this.getDataByType(res.included, 'games').map(game => {
                return {
                    id: game.id,
                    type: game.type,
                    name: game.attributes.name,
                    number_of_players: game.attributes.number_of_players,
                    number_of_substitute: game.attributes.number_of_substitute,
                    logo: logos.find(logo => logo.id === game.relationships.logo.data.id).attributes.url
                }
            })
            team.logo = logos.filter(logo => logo.id === team.relationships.logo.data.id)[0].attributes.url;
            team.background = background.filter(item => item.id === team.relationships.background.data.id)[0].attributes.url;

            team.compositions = this.getDataByType(res.included, 'compositions-for-disciplines').map(composition => {
                return {
                    id: composition.id,
                    game_id: composition.attributes.game_id,
                    players: composition.relationships.users.meta.roles
                }
            })


            for (let i = 0; i < team.compositions.length; i++) {
                for (let j = 0; j < team.compositions[i].players.length; j++) {
                    for (let n = 0; n < users.length; n++) {

                        if (Number(team.compositions[i].players[j].id) === Number(users[n].id)) {
                            let game = games.find(el => Number(el.id) === Number(team.compositions[i].game_id))
                            users[n].games.push(game)
                        }

                    }
                }
            }

            return {
                team,
                users,
                games,
            };
        })
    }

    async getStaticData() {
        const timeZones = await this.getTimeZones()
        const countries = await this.getCountries()
        const languages = await this.getLanguages()
        return {
            timeZones,
            countries,
            languages
        }
    }

    async getTimeZones() {
        const res = await API({
            url: '/static/timezones',
            method: 'GET'
        });

        const data = res.data.data;
        return data;
    }

    async getCountries() {
        const res = await API({
            url: '/static/countries',
            method: 'GET'
        });

        const data = res.data.data;
        return data;
    }

    async getLanguages() {
        const res = await API({
            url: '/static/languages',
            method: 'GET'
        });

        const data = res.data.data;
        return data;
    }

    getDataByTag(arr, tag) {
        return includeFilteringByTag(arr,tag);
    }
    getDataByType(arr, type) {
        return includeFilteringByType(arr, type)
    }
    getDataByUserRole(arr, role) {
        return filteringByUserRole(arr, role)
    }
}

